.login-wait-message {
    width: 100%;
    text-align: center;
    padding: 10px;
}

.vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
