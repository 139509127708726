.profile-overview {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.profile-overview > * {
    margin: 12px;
}

.profile-overview .profile-picture {
    text-align: center;
}
